var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('portal',{attrs:{"to":"title-page"}},[_c('h1',[_vm._v("Listado de reservaciones por dia")])]),(_vm.items != null)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"item-key":"id","search":_vm.search,"fixed-header":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-top-bar"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,4035985707),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"items":_vm.panels,"item-text":"text","item-value":"value","label":"panel"},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-btn',{staticClass:"mt-md-3",attrs:{"small":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.searchReservations()}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Buscar")],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [(!item.confirmationInfo)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openModalConfirmationInfo(item)}}},[_vm._v(" "+_vm._s(_vm.mdiCheckboxBlankCircleOutline)+" ")]):_vm._e(),(item.confirmationInfo)?_c('v-icon',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.mdiCheckCircle)+" ")]):_vm._e(),_c('br'),(item.pickupDateTime)?[_vm._v(" "+_vm._s(_vm.$moment(item.pickupDateTime.seconds * 1000).format('HH:mma'))+" ")]:_vm._e()]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" "),_c('a',{attrs:{"href":"#"}},[_c('small',[_vm._v(_vm._s(item.panelFormated))])])]}},{key:"item.tour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tour)+" ")]}},{key:"item.pax",fn:function(ref){
var item = ref.item;
return [(item.adults > 0)?_c('div',[_vm._v("Adultos: "+_vm._s(item.adults))]):_vm._e(),(item.elderlies > 0)?_c('div',[_vm._v("A.Mayores: "+_vm._s(item.elderlies))]):_vm._e(),(item.children > 0)?_c('div',[_vm._v("Niños: "+_vm._s(item.children))]):_vm._e(),(item.infants > 0)?_c('div',[_vm._v("Infantes: "+_vm._s(item.infants))]):_vm._e()]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.customerEmail))]),_c('div',[_vm._v(_vm._s(item.customerName)+" "+_vm._s(item.customerLastname))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("/backend/taquilla/reservations/" + (item.id) + "/edit")},on:{"click":function($event){return _vm.redirectTo(("/backend/taquilla/reservations/" + (item.id) + "/edit"), $event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('BtnStatus',{attrs:{"icon":_vm.mdiPencil,"status":item.status},nativeOn:{"click":function($event){return _vm.test($event)}}})]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('BtnPaymentStatus',{attrs:{"icon":_vm.mdiPencil,"status":item.paymentStatus}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.total)),_c('small',[_vm._v(_vm._s(item.currency))])]}}],null,false,818967718),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.reservation)?_c('ConfirmationInfo',{attrs:{"reservation":_vm.reservation}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }